<template>
  <form-field :name="field.name">
    <ifinancing-packages
      v-model="input"
      v-bind="field.props"
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  components: {
    IfinancingPackages: () => import(/* webpackChunkName: "ifinancingPackages" */ '@/components/IfinancingPackages'),
  },
  mixins: [formField],
  data: function () {
    return {
      input: {},
    }
  },
  watch: {
    input: function (val) {
      this.field.value = val
    },
  },
}
</script>
